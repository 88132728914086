import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { motion } from 'framer-motion'
import scrollTo from 'gatsby-plugin-smoothscroll'
import ArrowRight from "../components/svg/arrowRight"
import Seo from "../components/seo"
import {StaticImage} from "gatsby-plugin-image"
import Cta from "../components/cta"

const WfPorti = () => (
    <Layout>
        <Seo 
            title="Webflow website design portfolio" 
            description="Portfolio of Webflow websites designed and developed by Launch Lab."
        />

        <section role="main" className="bg-purple white">
          <div className="container">
              <div className="row flex flex-jc">
                  <div className="hero-content sub-hero">
                      <h1
                      className="small-h"
                      >Webflow Portfolio</h1>
                      <motion.h2 className="hero-lead"
                        initial="hidden"
                        animate={{ opacity: [0, 0.25, 0.5, 0.75, 1], y: [80, 0] }} 
                        transition={{ ease: "easeOut", duration: 0.5 }}
                      >
                          A collection of Webflow websites we've recently worked on
                      </motion.h2>
                      <div className="btn-row">
                        <motion.div 
                            initial="hidden"
                            animate={{ opacity: [0, 0.25, 0.5, 0.75, 1], x: [-80, 0] }} 
                            transition={{ ease: "easeOut", duration: 0.5 }}
                        > 
                            <button onClick={() => scrollTo('#anchor')} className="btn btn-lg btn-pink">
                                <span className="flex flex-ac down">
                                <ArrowRight />
                                </span>
                            </button>
                        </motion.div>
                      </div>
                  </div>
                </div>
            </div>
        </section>

        <section className="section-pad-sm" id="anchor">
            <div className="container">
                <div className="row">   
                    <motion.div 
                        className="porti-row mt-3"
                        initial={{ opacity: 0, y: 120 }}
                        whileInView={{ opacity: [0, 0.25, 0.5, 0.75, 1], y: 0, scale: [0.9, 1] }}
                        viewport={{ once: true }}
                        transition={{ ease: "easeOut", delay: "0.25", duration: 0.5 }}
                    >

                        <Link to="/legal-tech-startup-case-study/" className="porti-item">
                            <div className="porti-item-img bg-noise apos">
                                <StaticImage 
                                    src="../images/oxrooms-webflow.png"
                                    alt="A collage of the OX Rooms Webflow website"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    placeholder="none"
                                    backgroundcolor="white"
                                    layout="fullWidth"
                                    quality="100%"
                                />
                            </div>
                            <div className="porti-item-text">
                                <h3>OX Rooms</h3>
                                <p>We designed and developed a Webflow website for this legal tech Saas startup.</p>
                                <div className="flex flex-wrap">
                                    <div className="work-pill website">Website</div>
                                    <div className="work-pill webapp">Webflow</div>
                                    <div className="work-pill cms">CMS</div>
                                </div>
                            </div>
                        </Link>
                        <Link to="/case-study-giraffe-webflow-website/" className="porti-item">
                            <div className="porti-item-img bg-noise giraffe">
                                <StaticImage 
                                    src="../images/giraffe.png"
                                    alt="A collage of the Giraffe website screens"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    placeholder="none"
                                    backgroundcolor="white"
                                    layout="fullWidth"
                                    quality="100%"
                                />
                            </div>
                            <div className="porti-item-text">
                                <h3>Giraffe</h3>
                                <p>We designed and developed a new Webflow website for this Australian startup.</p>
                                <div className="flex flex-wrap">
                                    <div className="work-pill website">Website</div>
                                    <div className="work-pill webapp">Webflow</div>
                                    <div className="work-pill cms">CMS</div>
                                </div>
                            </div>
                        </Link>
                        <Link to="/game-studio-website/" className="porti-item">
                            <div className="porti-item-img bg-noise apos">
                                <StaticImage 
                                    src="../images/bzt.jpg"
                                    alt="Bazooka Tango homepage screen grab"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    placeholder="none"
                                    backgroundcolor="white"
                                    layout="fullWidth"
                                    quality="100%"
                                />
                            </div>
                            <div className="porti-item-text">
                                <h3>Bazooka Tango</h3>
                                <p>We designed and developed a new website for this US-based game studio.</p>
                                <div className="flex flex-wrap">
                                    <div className="work-pill website">Website</div>
                                    <div className="work-pill webapp">Webflow</div>
                                </div>
                            </div>
                        </Link>
                        <Link to="/wordpress-to-webflow-case-study/" className="porti-item">
                            <div className="porti-item-img bg-noise apos">
                                <StaticImage 
                                    src="../images/apositive.png"
                                    alt="A collage of the APositive website screens"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    placeholder="none"
                                    backgroundcolor="white"
                                    layout="fullWidth"
                                    quality="100%"
                                />
                            </div>
                            <div className="porti-item-text">
                                <h3>APositive</h3>
                                <p>We designed and developed a new website for this Financial services company and helped them migrate from Wordpress to Webflow.</p>
                                <div className="flex flex-wrap">
                                    <div className="work-pill website">Website</div>
                                    <div className="work-pill webapp">Webflow</div>
                                    <div className="work-pill cms">CMS</div>
                                </div>
                            </div>
                        </Link>
                        <Link to="/webflow-website-case-study/" className="porti-item">
                            <div className="porti-item-img bg-noise immu">
                                <StaticImage 
                                    src="../images/immutable-site.png"
                                    alt="Multiple screens from the Immutable website"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    placeholder="none"
                                    backgroundcolor="white"
                                    layout="fullWidth"
                                    quality="100%"
                                />
                            </div>
                            <div className="porti-item-text">
                                <h3>Immutable</h3>
                                <p>We developed the Webflow website for this Sydney-based Web3 startup.</p>
                                <div className="flex flex-wrap">
                                    <div className="work-pill website">Website</div>
                                    <div className="work-pill webapp">Webflow Enterprise</div>
                                    <div className="work-pill cms">CMS</div>
                                </div>
                            </div>
                        </Link>

                        <a href="https://www.gspengagement.com.au/" target="_blank" rel="noopener noreferrer" title="View the Greater Sydney Parklands community engagement website" className="porti-item">
                            <div className="porti-item-img">
                                <StaticImage 
                                    src="../images/nsw-webflow-website.png"
                                    alt="Example of a Webflow website homepage"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    placeholder="none"
                                    backgroundcolor="white"
                                    layout="fullWidth"
                                    quality="100%"
                                />
                            </div>
                            <div className="porti-item-text">
                                <h3>NSW Government</h3>
                                <p>We designed and developed a community engagement website for Greater Sydney Parklands.</p>
                                <div className="flex flex-wrap">
                                    <div className="work-pill website">Website</div>
                                    <div className="work-pill webapp">Webflow</div>
                                    <div className="work-pill cms">CMS</div>
                                </div>
                            </div>
                        </a>

                        <Link to="/fixitdoc-case-study/" className="porti-item">
                            <div className="porti-item-img">
                                <StaticImage 
                                    src="../images/fixitdoc-screens.png"
                                    alt="FixitDoc mobile aplication"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    placeholder="none"
                                    backgroundcolor="white"
                                    layout="fullWidth"
                                    quality="100%"
                                />
                            </div>
                            <div className="porti-item-text">
                                <h3>FixitDoc</h3>
                                <p>We developed a Webflow website and a mobile app which makes use of the Webflow API.</p>
                                <div className="flex flex-wrap">
                                    <div className="work-pill website">Website</div>
                                    <div className="work-pill cms">CMS</div>
                                    <div className="work-pill mobileapp">Mobile App</div>
                                    <div className="work-pill startup">Startup</div>
                                </div>
                            </div>
                        </Link>

                        <a href="https://www.digitalworldsnfts.com/" target="_blank" rel="noopener noreferrer" title="View the Digital Worlds NFTs website" className="porti-item">
                            <div className="porti-item-img">
                                <StaticImage 
                                    src="../images/gods-unchained.png"
                                    alt="Gods Unchained web3 game"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    placeholder="none"
                                    backgroundcolor="white"
                                    layout="fullWidth"
                                    quality="100%"
                                />
                            </div>
                            <div className="porti-item-text">
                                <h3>Digital Worlds NFTs</h3>
                                <p>Launch Lab developed a Webflow website for this non-profit organisation.</p>
                                <div className="flex flex-wrap">
                                    <div className="work-pill website">Website</div>
                                    <div className="work-pill webapp">Webflow</div>
                                    <div className="work-pill cms">CMS</div>
                                </div>
                            </div>
                        </a>

                        <a href="https://www.advoc8.co/" target="_blank" rel="noopener noreferrer" title="View the Advoc8 website" className="porti-item">
                            <div className="porti-item-img">
                                <StaticImage 
                                    src="../images/advoc8-website.png"
                                    alt="Advoc8 website home page"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    placeholder="none"
                                    backgroundcolor="white"
                                    layout="fullWidth"
                                    quality="100%"
                                />
                            </div>
                            <div className="porti-item-text">
                                <h3>Advoc8</h3>
                                <p>We designed and developed a Webflow website for this Australian startup.</p>
                                <div className="flex flex-wrap">
                                    <div className="work-pill website">Website</div>
                                    <div className="work-pill webapp">Webflow</div>
                                    <div className="work-pill cms">CMS</div>
                                </div>
                            </div>
                    </a>

                        <a href="https://www.ooompf.com.au/" target="_blank" rel="noopener noreferrer" title="View the Ooompf website" className="porti-item">
                            <div className="porti-item-img">
                                <StaticImage 
                                    src="../images/ooompf-webflow-website.png"
                                    alt="Ooompf Webflow website"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    placeholder="none"
                                    backgroundcolor="white"
                                    layout="fullWidth"
                                    quality="100%"
                                />
                            </div>
                            <div className="porti-item-text">
                                <h4>Ooompf Clear Aligners</h4>
                                <p>Ooompf has already purchased a Webflow template. We changed the template to match their brand and to fit their content.</p>
                                <div className="flex flex-wrap">
                                    <div className="work-pill website">Website</div>
                                    <div className="work-pill webapp">Webflow</div>
                                </div>
                            </div>
                        </a>

                        <a href="https://www.propa.productions/" target="_blank" rel="noopener noreferrer" title="View the Propa Productions Webflow website" className="porti-item">
                            <div className="porti-item-img">
                                <StaticImage 
                                    src="../images/videographer-website.png"
                                    alt="Propa Productions Webflow website"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    placeholder="none"
                                    backgroundcolor="white"
                                    layout="fullWidth"
                                    quality="100%"
                                />
                            </div>
                            <div className="porti-item-text">
                                <h3>Propa Productions</h3>
                                <p>We helped a Sydney Director create a beautifully simple, and cost effective, Webflow website.</p>
                                <div className="flex flex-wrap">
                                    <div className="work-pill website">Website</div>
                                    <div className="work-pill cms">CMS</div>
                                    <div className="work-pill webapp">Webflow</div>
                                </div>
                            </div>
                        </a>

                        <a href="https://www.digitaltwinguide.com.au/" target="_blank" rel="noopener noreferrer" title="View the WSP Digital Twin website" className="porti-item">
                            <div className="porti-item-img">
                                <StaticImage 
                                    src="../images/wsp-webflow-website.png"
                                    alt="WSP Digital Twin website"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    placeholder="none"
                                    backgroundcolor="white"
                                    layout="fullWidth"
                                    quality="100%"
                                />
                            </div>
                            <div className="porti-item-text">
                                <h3>WSP Digital Twin</h3>
                                <p>We assisted WSP with custom Javascript, Webflow design and CMS Collection troubleshooting.</p>
                                <div className="flex flex-wrap">
                                    <div className="work-pill website">Website</div>
                                    <div className="work-pill cms">CMS</div>
                                    <div className="work-pill webapp">Webflow</div>
                                </div>
                            </div>
                        </a>

                    </motion.div>
                </div>
            </div>
        </section>

        <div className="container">
            <div className="row">
            <div className="section-line"></div>
            </div>
        </div>

        <section className="section-pad">
          <div className="container">
            <div className="row">
              <motion.div
                initial={{ opacity: 0, y: 80 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ ease: "easeOut", duration: 0.5 }}
              >
                <div className="small-h">No code websites</div>
                <h4>A no-code solution to building CMS driven websites faster and at a lower cost</h4>
                <p className="lead">
                    Due to its power, popularity and ability to speed up website development Launch Lab now also offers <strong>Webflow development</strong> services. The collection below contains some recent Webflow work on both large and small websites. 
                </p>
                <p className="lead">
                    If you're looking for an experienced <Link to="/webflow-developer/">Webflow developer in Australia</Link>, or would like to discuss if Webflow is a good fit for your needs, we'd love to hear from you. 
                </p>
                <div className="btn-row">
                    <Link to="/contact/" className="btn btn-lg btn-purple">
                        <span className="flex flex-ac">
                            Contact us
                            <ArrowRight />
                        </span>
                    </Link>
                </div>
              </motion.div>
            </div>
          </div>
        </section>

        <section className="bg-purple section-pad-sm white">
            <div className="container">
                <div className="row long-text">
                    <p>The projects above are a small sample of our portfolio. Launch Lab designs and develops websites, CMS' (headless as well as Webflow) and builds tech startups for non-technical founders. We work with idea stage startups, growth startups, established brands and government.</p>
                </div>
            </div>
        </section>
        
        <Cta 
            text="Get an obligation free web design or web development quote from an experienced local Australian Webflow team."
        />
    </Layout>
)

export default WfPorti